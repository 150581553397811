import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router'; // 确保你有一个router.js或router/index.js文件来配置路由
import store from './store/store';
import VueAwesomeSwiper from'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.use(VueRouter);
import axios from 'axios'
// 创建axios实例
let requestUrl = process.env.NODE_ENV === 'development' ? 'http://bbsf.test/api/' :
	'https://px.aibaiba.com/api/';
const service = axios.create({
  baseURL: requestUrl, // api的base_url
  timeout: 5000 // 请求超时时间
})
 
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加请求头等信息
    return config
  },
  error => {
    // 请求错误处理
    return Promise.reject(error)
  }
)
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做处理，例如只返回data部分
    return response.data
  },
  error => {
    // 响应错误处理
    return Promise.reject(error)
  }
)
 
// 全局请求方法
Vue.prototype.$http = function(options) {
  return service(options)
}
new Vue({
  router, // 确保将router传递给Vue实例
  store,
  render: h => h(App),
}).$mount('#app');