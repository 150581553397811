<template>
  <div class="container">
    <!-- <image src="../../static/img/gongsijieshao.png" mode="" class="main_img"></image> -->
    <img
      src="../assets/img/gongsijieshao.jpg"
      alt=""
      mode=""
      class="main_img"
    />
    <div
      class="flex space-between"
      style="padding-left: 200px; padding-right: 128px"
    >
      <div class="left" style="padding-top: 26px">
        <div
          class="left_list"
          v-for="(item, index) in navList"
          :key="index"
          :class="navHover == item.value ? 'hover' : ''"
          @click="navClick(item, index)"
        >
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="right flex" v-if="navHover == 1">
        <div class="" style="margin-left: 113px; margin-top: 123px">
          <div class="right_title">Company Profile</div>
          <div class="right_cont">江苏百霸智学科技有限公司</div>
          <div class="right_pro">
            百霸智学是一家打破传统的教育服务平台，致力于通过创新的教育模式和技术支持，为学校、教育培训机构及学生提供以确定性结果交付为目标的综合解决方案。我们专注于初高中学生的成长与发展，帮助家长实现教育目标，并通过系统性的教学技术、个性化学习规划以及全面的训练方案，助力每一位学生取得优异的成绩并培养终身学习的习惯。赋能教育，成就彼此，为教育领域开创全新体系。
          </div>
        </div>
        <img src="../assets/img/nj.png" alt="" mode="" class="nb_img" />
      </div>
      <div class="right" v-if="navHover == 2">
        <div class="" style="margin-left: 113px; margin-top: 123px">
          <div class="right_pro">
            百霸智学是由拥有25年教学经验的赵红旗老师创立的新型文化教育产业项目。赵老师的教育生涯始于其对教学的热情和对学习方法的深入研究，他通过20多年的实践总结出了一套独特而有效的方法，这套方法不仅有助于升学进步，还能帮助学生快速提分。
          </div>
          <div class="right_pro">
            通过不断的技术创新和服务升级，百霸智学已经成为了一个具有深远影响力的教育品牌，它不仅承载着赵红旗老师对教育理想的执着追求，也见证了一个本土教育企业从无到有、从小到大的成长历程。
          </div>
        </div>
        <img
          src="../assets/img/Group 5508.png"
          alt=""
          mode=""
          style="width: 100%; height: auto"
        />
      </div>
      <div class="right" v-if="navHover == 3">
          <div class="flex item-center " style="margin-top: 94px;"> 
            <!-- <img src="../assets/img/Mask group (2).png" alt=""> -->
             <img src="../assets/img/Frame 5496 (1).png" alt="" style="width: 100%;height: 100%;">
          </div>
      </div>
      <div class="right" v-if="navHover == 4">
        <div class="flex items-center">
          <img
            src="../assets/img/Group 5510.png"
            alt=""
            mode=""
            style="width: 100%;height: auto; margin-left: 100px"
          />
          <img
            src="../assets/img/Group 5511 (1).png"
            alt=""
            mode=""
            style="width: 100%;height: auto; margin-left: 96px"
          />
        </div>
        <img
          src="../assets/img/Group 5512.png"
          alt=""
          mode=""
          style="width: 100%;height: auto; margin-left: 100px"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import {
// 	authAPI
// } from '@/api/auth.js'
export default {
  data() {
    return {
      navList: [
        {
          name: "公司简介",
          value: 1,
        },
        {
          name: "发展历程",
          value: 2,
        },
        {
          name: "创始人介绍",
          value: 3,
        },
        {
          name: "公司理念",
          value: 4,
        },
      ],
      navHover: 1,
    };
  },

  created() {
    this.navHover = this.$route.query.navHover||1;
    // this.getNews();
  },
  methods: {
    navClick(item) {
      this.navHover = item.value;
    },
  },
};
</script>

<style scoped lang="scss">
.main_img {
  width: 100%;
  // height: 600px;
}

.left {
  .left_list {
    width: 119px;
    margin-bottom: 24px;

    .name {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
    }
  }

  .hover {
    .name {
      color: #ec4853;
      border-bottom: 1px solid #ec4853;
    }
  }
}
.right {
  width: 1553px;
  border-radius: 0px 0px 0px 0px;
  border-left: 1px solid #d9d9d9;
  margin-left: 48px;
  .right_title {
    font-weight: 500;
    font-size: 48px;
    color: #ffebec;
  }
  .right_cont {
    font-weight: bold;
    font-size: 36px;
    color: #ec4853;
  }
  .right_pro {
    width: 573px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin-top: 71px;
  }
  .nb_img {
    width: 582px;
    height: 382px;
    margin-left: 157px;
    margin-top: 135px;
  }
}
</style>