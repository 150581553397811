<template>
  <div class="container">
    <img src="../assets/img/xuefaketang.png" alt="" class="main_img" />
    <div class="flex flex-center">
      <div class="left" style="padding-top: 26px">
        <div
          class="left_list"
          v-for="(item, index) in navList"
          :key="index"
          :class="navHover == item.value ? 'hover' : ''"
          @click="navClick(item, index)"
        >
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="" style="margin-left: 113px">
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_cont">核心陪学方法</div>
            </div>
            <div class="nj_img" style="margin-top: 53px">
              <img src="../assets/img/9.png" mode="" />
              <img src="../assets/img/10.png" mode="" />
              <img src="../assets/img/11.png" mode="" />
              <img src="../assets/img/12.png" mode="" />
              <img src="../assets/img/13.png" mode="" />
            </div>
          </div>
        </div>
        <div class="" style="margin-left: 113px">
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_cont">红旗老师以往演讲</div>
            </div>
            <div class="nj_img" style="margin-top: 53px">
              <img src="../assets/index/yj/1.png" mode="" />
              <img src="../assets/index/yj/2.png" mode="" />
              <img src="../assets/index/yj/3.png" mode="" />
              <img src="../assets/index/yj/4.png" mode="" />
              <img src="../assets/index/yj/5.png" mode="" />
              <img src="../assets/index/yj/6.png" mode="" />
            </div>
          </div>
        </div>
        <div class=" flex ">
				<div class="" style="margin-left: 113px;">
					<div class="" style="margin-top: 133px;">
						<div class="flex">
							<div class="right_cont">
								课堂教学
							</div>
						</div>
						<div class="nj_img" style="margin-top: 53px;">
							<img src="../assets/img/ketang/1.png" mode="">
							<img src="../assets/img/ketang/2.png" mode="">
              <img src="../assets/img/ketang/3.png" mode="">
              <img src="../assets/img/ketang/4.png" mode="">
							<img src="../assets/img/ketang/5.png" mode="">
							<img src="../assets/img/ketang/6.png" mode="">
						</div>
					</div>
				</div>
			</div>
			<div class="  ">
				<div class="" style="margin-left: 113px;">
					<div class="" style="margin-top: 133px;">
						<div class="flex">
							<div class="right_cont">
								参加活动
							</div>
						</div>
						<div class="nj_img" style="margin-top: 53px;">
							<img src="../assets/index/yj/1.png" mode="">
							<img src="../assets/index/yj/2.png" mode="">
							<img src="../assets/index/yj/3.png" mode="">
							<img src="../assets/index/yj/4.png" mode="">
							<img src="../assets/index/yj/5.png" mode="">
							<img src="../assets/index/yj/6.png" mode="">
						</div>
					</div>
				</div>
			</div>
      </div>
    </div>
  </div>
</template>

<script>
// import {
// 	authAPI
// } from '@/api/auth.js'
export default {
  data() {
    return {
      navList: [
        {
          name: "学法课堂",
          value: 1,
        },
      ],
      navHover: 1,
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.main_img {
  width: 100%;
  // height: 750px;
}

.left {
  .left_list {
    width: 80px;
    margin-bottom: 24px;

    .name {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
    }
  }

  .hover {
    .name {
      color: #ec4853;
      border-bottom: 1px solid #ec4853;
    }
  }
}

.right {
  width: 1553px;
  border-radius: 0px 0px 0px 0px;
  border-left: 1px solid #D9D9D9;
  margin-left: 48px;

  .right_title {
    font-weight: bold;
    font-size: 40px;
    color: #ec4853;
  }

  .right_cont {
    font-weight: bold;
    font-size: 36px;
    color: #333333;
    margin-left: 12px;
  }

  .right_pro {
    width: 573px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin-top: 71px;
  }

  .nb_img {
    width: 783px;
    height: 419px;
    margin-left: 157px;
    margin-top: 100px;
  }

  .right_list {
    width: 250px;
    height: 313px;
    background: #fff5f6;
    border-radius: 30px 30px 30px 30px;
    padding: 44px 33px 0 33px;
    margin-right: 90px;

    .rl_img {
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto 30px;
    }

    .rl_title {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .rl_cont {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
    }
  }

  .right_list2 {
    width: 569px;
    height: 272px;
    background: #fff5f6;
    border-radius: 30px 30px 30px 30px;
    padding: 40px 12px 0 45px;
    margin-right: 62px;
    margin-bottom: 50px;

    .rl2_img {
      width: 228px;
      height: 228px;
      margin-left: 14px;
    }

    .rl2_title {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
    }

    .rl2_cont {
      width: 322px;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
    }
  }

  .right_img1 {
    width: 1200px;
    height: 1263px;
  }

  .right_img2 {
    width: 580px;
    height: 435px;
    margin-right: 40px;
    margin-bottom: 40px;
  }

  .right_img3 {
    width: 1200px;
    height: 900px;
  }

  .nj_img {
    img {
      // width: 419px;
      height: 294px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}
</style>