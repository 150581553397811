
// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        navValue: localStorage.getItem("navValue") || 1
    },
    mutations: {
        setnavValue(state, newTitle) {
            state.navValue = newTitle;
        }
    },
    actions: {
        updatenavValue({ commit }, newTitle) {
            commit('setnavValue', newTitle);
        }
    }
});