<template>
	<div class="footer">
		<div class="flex flex-center" style="width: 1200px;margin: 0 auto;">
			<div class="footer_list" >
				<div class="title" >
					公司介绍
				</div>
				<div class="cont" @click="goCom(1)">
					公司简介
				</div>
				<div class="cont" @click="goCom(2)">
					公司发展历程
				</div>
				<div class="cont" @click="goCom(3)">
					公司创始人介绍
				</div>
				<div class="cont" @click="goCom(4)">
					公司理念
				</div>
			</div>
			<div class="footer_list" >
				<div class="title">
					产品和服务
				</div>
				<div class="cont" @click="goServices(1)">
					百霸智学及模式
				</div>
				<div class="cont" @click="goServices(2)">
					百霸智学产品
				</div>
				<div class="cont" @click="goServices(3)">
					百霸书房
				</div>
				<div class="cont" @click="goServices(4)">
					百霸智学项目
				</div>
				
			</div>
			<div class="footer_list" >
				<div class="title">
					学法课堂
				</div>
				<div class="cont" @click="goClass(2)">
					学法课堂
				</div>
			</div>
			<!-- <div class="footer_list" >
				<div class="title">
					百霸团队
				</div>
				<div class="cont" @click="goAbout(1)">
					导学老师团队
				</div>
				<div class="cont" @click="goAbout(2)">
					陪学老师团队
				</div>
				<div class="cont" @click="goAbout(3)">
					运营管理团队
				</div>
			</div> -->
			<div class="footer_list">
				<div class="title">
					最新资讯
				</div>
				<div class="cont"  @click="goNew(15)">
					公司新闻
				</div>
				<div class="cont"  @click="goNew(16)">
					行业资讯
				</div>
			</div>
			<div class="footer_list2">
				<div class="title">
					联系合作
				</div>
				<div class="cont">
					总部
				</div>
		<!-- 		<div class="pro">
					联系方式：13357732383
				</div> -->
				<div class="pro">
					地址：江苏省南京市鼓楼区中山北路105号中环国际广场8A02
				</div>
				<div class="cont">
					郑州校区
				</div>
				<div class="pro">
					地址：河南省郑州市中原区桐柏南路9号办公楼第六层
				</div>
				<div class="cont">
					宁波校区
				</div>
			<!-- 	<div class="pro">
					联系方式：19329003229
				</div> -->
				<div class="pro">
					地址：浙江省宁波市镇海区庄市街道金色广场
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="line_cont text-center">
			地址：江苏省南京市鼓楼区中山北路105号中环国际广场8A02
		</div>
		<div class="line_cont text-center">
		Copyright Ⓒ2024 百霸文化传播有限公司版权所有 苏ICP备17028056号-3
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				type:'',
			}
		},
	
		methods:{
			goNew(type){
				this.type=type
				// uni.navigateTo({
				// 	url:'/pages/index/news?navHover='+type
				// })
			},
			goClass(type){
				this.type=type
				// uni.navigateTo({
				// 	url:'/pages/index/classroom?navHover='+type
				// })
			},
			goAbout(type){
				this.type=type
				// uni.navigateTo({
				// 	url:'/pages/index/about?navHover='+type
				// })
			},
			goServices(type){
				this.type=type
				// uni.navigateTo({
				// 	url:'/pages/index/services?navHover='+type
				// })
			},
			goCom(type){
				this.type=type
				// uni.navigateTo({
				// 	url:'/pages/index/companyProfile?navHover='+type
				// })
			},
		}
	}
</script>

<style scoped lang="scss">
	.footer{
		width: 100%;
		height: 467px;
		padding: 40px 0 24px 0;
		background: #EC4853;
		border-radius: 0px 0px 0px 0px;
		margin-top: 160px;
		.footer_list{
			width: 180px;
			height: 166px;
			border-radius: 0px 0px 0px 0px;
			.title{
				font-weight: bold;
				font-size: 18px;
				color: #FFFFFF;
				margin-bottom: 24px;
			}
			.cont{
				font-weight: 400;
				font-size: 14px;
				color: #FFE1E3;
				margin-bottom: 14px;
			}
		}
		.footer_list2{
			width: 426px;
			height: 283px;
			border-radius: 0px 0px 0px 0px;
			.title{
				font-weight: bold;
				font-size: 18px;
				color: #FFFFFF;
				
			}
			.cont{
				font-weight: 500;
				margin-top: 16px;
				font-size: 16px;
				color: #FFFFFF;
			}
			.pro{
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
			}
		}
		.line{
			width: 1200px;
			height: 1px;
			background-color: #FFD3D6;
			margin: 114px auto 24px;
		}
		.line_cont{
			font-weight: 400;
			font-size: 12px;
			color: #FFFFFF;
		}
	}
</style>