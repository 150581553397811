<template>
  <div class="container">
    <div class="">
      <div class="swiper-container">
        <swiper :options="swiperOptions" class="swiper">
          <swiper-slide>
            <img src="../assets/banner/banner1.jpg" alt="" class="swiper-image" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/banner/banner2.jpg" alt="" class="swiper-image" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/banner/banner3.jpg" alt="" class="swiper-image" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/banner/banner4.jpg" alt="" class="swiper-image" />
          </swiper-slide>
          <!-- 如果你需要分页器 -->
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
      <div class="box1">
        <div class="flex relative">
          <div class="box_left">
            <div class="box_title">Company Profile</div>
            <div class="box1_name">江苏百霸智学科技有限公司</div>
            <div class="box1_cont">
              百霸智学是一家打破传统的教育服务平台，致力于通过创新的教育模式和技术支持，为学校、教育培训机构及学生提供以确定性结果交付为目标的综合解决方案。我们专注于初高中学生的成长与发展，帮助家长实现教育目标，并通过系统性的教学技术、个性化学习规划以及全面的训练方案，助力每一位学生取得优异的成绩并培养终身学习的习惯。赋能教育，成就彼此，为教育领域开创全新体系。
            </div>
            <div class="flex" style="margin-top: 60px">
              <div
                class="box1_btn flex items-center flex-center"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
                @click="goFz()"
              >
                <img src="../assets/img/fazhan2.png" alt="" v-if="!hover" />
                <img src="../assets/img/fazhan.png" alt="" v-else />
                <div class="box1_btn_tit" >发展历程</div>
              </div>
              <div
                class="box1_btn flex items-center flex-center"
                @mouseenter="handleMouseEnter1"
                @mouseleave="handleMouseLeave1"
                @click="goCsr()"
              >
                <img src="../assets/img/jieshao.png" alt="" v-if="!hover1" />
                <img src="../assets/img/jieshao2.png" alt="" v-else />
                <div class="box1_btn_tit" >创始人介绍</div>
              </div>
            </div>
          </div>
          <div class="box1_right">
            <img src="../assets/img/Mask group@2x (1).png" alt="" />
          </div>
        </div>
        <div class="box1_bot flex items-center" @click="goCom()">
          <div class="flex items-center" style="margin-left: 59px">
            <div class="">
              <div class="box1_bot_title">想了解更多关于百霸？</div>
              <div class="box1_bot_pro">
                Do you want to learn more about Baiba?
              </div>
            </div>
            <img src="../assets/img/you.png" alt="" />
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="box2_title text-center">PRODUCTS AND SERVICES</div>
        <div class="box2_cont text-center">产品和服务</div>
        <div class="box2_lists">
          <div class="flex flex-center">
            <div
              class="box2_list"
              v-for="(item, index) in imgList"
              :key="index"
              :class="imgHover == index ? 'hover' : ''"
              @click="imgClick(item, index)"
            >
              <!-- <img src="../assets/index/img1.png" alt="" /> -->
              <img :src="item.img" alt="">
              <div class="title">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="flex flex-center">
            <div class="box2_list_cont flex " v-if="imgHover == 0">
            <div class="">
              <div class="flex items-center">
                <div class="num">01</div>
                <div class="num_title">百霸智学是什么？</div>
              </div>
              <div class="num_cont">
                是一整套帮助学生高效学习和提分、帮助学校和机构赋能的具有确定性结果交付能力的教育和学习解决方案。
              </div>
              <div class="num_btn" @click="goDetail()">了解详情</div>
            </div>
            <img src="../assets/index/Element.png" mode="" class="main_img" />
          </div>
          <div class="box2_list_cont flex  " v-if="imgHover == 1">
            <img src="../assets/zxcp/Frame 5.png" mode="" class="box2_img" />
            <img src="../assets/zxcp/Frame 2.png" mode="" class="box2_img" />
            <img src="../assets/zxcp/Frame 1.png" mode="" class="box2_img" />
          </div>
          <div class="box2_list_cont flex " v-if="imgHover == 2">
            <img src="../assets/index/nj/nj1.jpg" mode="" class="box2_img" />
            <img src="../assets/index/nj/nj3.jpg" mode="" class="box2_img" />
            <img src="../assets/index/nj/nj2.jpg" mode="" class="box2_img" />
          </div>
          <div class="box2_list_cont flex" v-if="imgHover == 3">
            <img src="../assets/index/xy/xy1.jpg" mode="" class="box2_img" />
            <img src="../assets/index/xy/xy2.jpg" mode="" class="box2_img" />
            <img src="../assets/index/xy/xy3.jpg" mode="" class="box2_img" />
          </div>
          </div>
        </div>
        <div class="detail_btn" v-if="imgHover != 0" @click="goDetail()">了解详情</div>
      </div>
      <div class="box3">
        <div class="box3_title text-center">Learning Law Classroom</div>
        <div class="box3_cont text-center">学法课堂</div>
        <!-- <div class="box3_lists flex">
          <div
            class="box3_list"
            :class="box3Hover == 1 ? 'hover' : ''"
            @click="box3HoverClick(1)"
          >
            学法展示
          </div>
          <div
            class="box3_list"
            :class="box3Hover == 2 ? 'hover' : ''"
            @click="box3HoverClick(2)"
          >
            学法课堂
          </div>
        </div> -->
        <div class="imgList flex flex-wrap" v-if="box3Hover == 1">
          <img src="../assets/index/xf/xf1.png" mode="" class="imgList1" />
          <img src="../assets/index/xf/xf2.png" mode="" class="imgList2" />
          <img src="../assets/index/xf/xf3.png" mode="" class="imgList2" />
          <img src="../assets/index/xf/xf4.png" mode="" class="imgList1" />
        </div>
        <div class="see_more" @click="moreClick()">查看更多</div>
        <!-- <div class="imgList flex flex-wrap" v-if="box3Hover == 2">
          <img src="../assets/img/1.png" mode="" class="imgList1" />
          <img src="../assets/img/2.png" mode="" class="imgList2" />
          <img src="../assets/img/3.png" mode="" class="imgList2" />
          <img src="../assets/img/4.png" mode="" class="imgList1" />
        </div> -->
      </div>
      <!-- box4 -->
      <!-- <div class="box4">
        <div class="flex flex-center" style="padding-top: 128px">
          <div class="box4_left">
            <div class="box4_left_title">Baiba Team</div>
            <div class="box4_left_cont">百霸团队</div>
            <div
              class="left_list"
              style="margin-top: 140px"
              :class="box4Hover == 1 ? 'hover' : ''"
              @click="box4HoverClick(1)"
            >
              课堂教学
            </div>
            <div
              class="left_list"
              style="margin-top: 57px"
              :class="box4Hover == 2 ? 'hover' : ''"
              @click="box4HoverClick(2)"
            >
              参加活动
            </div>
          </div>
          <div class="box4_right flex items-center" v-if="box4Hover == 1">
            <img src="../assets/img/IMG_3644.png" mode="" />
            <img src="../assets/img/IMG_4498.png" mode="" />
            <img src="../assets/img/IMG_4845.png" mode="" />
          </div>
          <div class="box4_right flex items-center" v-if="box4Hover == 2">
            <img src="../assets/img/5.png" mode="" />
            <img src="../assets/img/6.png" mode="" />
            <img src="../assets/img/7.png" mode="" />
          </div>
        </div>
      </div> -->
      <!-- box5 -->
      <div class="box5">
        <div class="box5_title text-center">Latest News</div>
        <div class="box5_cont text-center">最新资讯</div>
        <div
          class="flex flex-center"
          style="margin-top: 60px; margin-bottom: 70px"
        >
          <div
            class="box5_btn"
            :class="box5Hover == 15 ? 'hover' : ''"
            @click="box5HoverClick(15)"
          >
            公司新闻
          </div>
          <div
            class="box5_btn"
            :class="box5Hover == 16 ? 'hover' : ''"
            @click="box5HoverClick(16)"
          >
            行业资讯
          </div>
        </div>
        <div class="flex flex-center">
          <div class="box5_left">
            <img
              :src="info.image[0]"
              mode=""
              class="box5_img"
              v-if="info.image"
            />
            <div class="b5_title u-line-1">
              {{ info.title }}
            </div>
            <div class="b5_cont u-line-2">
              <div v-html="info.content"></div>
            </div>
          </div>
          <div class="box5_right">
            <div class="b5r_list" v-for="(item, index) in newList" :key="index">
              <div class="b5r_title u-line-1">
                {{ item.title }}
              </div>
              <div class="b5r_cont u-line-2">
                <div v-html="item.content"></div>
              </div>
              <div class="flex space-between" style="margin-top: 20px">
                <div class="b5r_time">
                  {{ item.releasetime }}
                </div>
                <div class="flex items-center" @click="gengduo()">
                  <div class="b5r_time">MORE</div>
                  <img
                    src="../assets/img/more.png"
                    mode=""
                    class="b5r_more_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {
// 	authAPI
// } from '@/api/auth.js'
export default {
  data() {
    return {
      list1: [
        "../assets/banner/banner3.jpg",
        "../assets/banner/banner1.jpg",
        "../assets/banner/banner2.jpg",
        "../assets/banner/banner4.jpg",
      ],
      hover: false,
      hover1: false,
      imgList: [
        {
          img: "http://bbsf.oss-cn-shanghai.aliyuncs.com/web/cp1.png",
          name: "百霸智学及模式",
        },
        {
          img: "http://bbsf.oss-cn-shanghai.aliyuncs.com/web/cp2.png",
          name: "百霸智学产品",
        },
        {
          img: "http://bbsf.oss-cn-shanghai.aliyuncs.com/web/cp3.png",
          name: "百霸书房",
        },
        {
          img: "http://bbsf.oss-cn-shanghai.aliyuncs.com/web/cp4.png",
          name: "百霸智学项目",
        },
      ],
      imgHover: 0,
      box3Hover: 1,
      box4Hover: 1,
      box5Hover: 15,
      info: {},
      newList: [],
      swiperOptions: {
        // 这里配置Swiper的参数
        // 例如：自动播放、分页等
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 其他配置...
      },
    };
  },

  created() {
    console.log(this.imgList, 265);
    this.getNews();
  },
  mounted() {},

  methods: {
    handleMouseEnter() {
      this.hover = true;
    },
    handleMouseLeave() {
      this.hover = false;
    },
    handleMouseEnter1() {
      this.hover1 = true;
    },
    handleMouseLeave1() {
      this.hover1 = false;
    },
    imgClick(item, index) {
      this.imgHover = index;
    },
    box3HoverClick(index) {
      this.box3Hover = index;
    },
    box4HoverClick(index) {
      this.box4Hover = index;
    },
    box5HoverClick(index) {
      this.box5Hover = index;
      this.getNews();
    },
    moreClick() {
      this.$router.push({ name: "classRoom",  });
      this.$store.dispatch('updatenavValue',4);
      localStorage.setItem("navValue", 4);
    },
    getNews() {
      this.$http({
        method: "post",
        url: "/Web.News/homenews",
        data:{
          is_home: 1,
					category_id: this.box5Hover
        }
      })
        .then((data) => {
          console.log(data);
          this.info = data.data.list1;
          this.newList = data.data.list2;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    goFz() {
      this.$router.push({ name: "companyProfile", query: { navHover: 2 } });
      localStorage.setItem('navValue', 2);
      this.$store.dispatch('updatenavValue',2);
      
    },
    goCsr() {
      this.$router.push({ name: "companyProfile", query: { navHover: 3 } });
      localStorage.setItem('navValue', 2);
      this.$store.dispatch('updatenavValue',2);
    },
    goCom(){
      this.$router.push({ name: "companyProfile", query: { navHover: 1 } });
      localStorage.setItem('navValue', 2);
      this.$store.dispatch('updatenavValue',2);
    },
    goDetail(){
      this.$router.push({ name: "servicesHtml", query: { navHover: this.imgHover } });
      this.$store.dispatch('updatenavValue',3);
      localStorage.setItem("navValue", 3);
    },
    gengduo(){
      this.$router.push({ name: "newsHtml", });
      this.$store.dispatch('updatenavValue',6);
      localStorage.setItem("navValue", 6);
    }
  },
};
</script>

<style scoped lang="scss">
.swiper {
  width: 100%;
    height: auto;
  // margin: 0 auto;
}
.swiper-container {
  width: 100%; /* 确保容器宽度为100% */
}
 
.swiper-image {
  width: 100%; /* 图片宽度为100%，根据父容器调整 */
  height: auto; /* 高度自动，保持图片比例 */
  display: block; /* 移除图片底部的间隙 */
}
.swiper-button-prev,
.swiper-button-next {
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 0px 0px;
  border-radius: 50%;
  color: #fff;
}
.box1 {
  padding: 0 0px 0 0px;
  height: 833px;
  border-radius: 0px 0px 0px 0px;
  margin: 40px auto 0;
  width: 1560px;

  .box_title {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 48px;
    color: #ffebec;
  }

  .box1_name {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: bold;
    font-size: 36px;
    color: #ec4853;
  }

  .box1_cont {
    font-family: Source Han Sans SC, Source Han Sans SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin-top: 71px;
    width: 573px;
  }

  .box1_btn {
    width: 178px;
    height: 68px;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #cccccc;
    margin-right: 30px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .box1_btn_tit {
      font-weight: 500;
      font-size: 20px;
      color: #999999;
    }
  }

  .box1_btn:hover {
    background: #ec4853;
    border: none;

    .box1_btn_tit {
      color: #fff;
    }
  }

  .box1_right {
    img {
      width: 731px;
      height: 771px;
      position: absolute;
    }
  }

  .box1_bot {
    width: 100%;
    height: 196px;
    background: #ec4853;
    border-radius: 0px 0px 0px 0px;
    margin-top: 119px;

    .box1_bot_title {
      font-weight: 400;
      font-size: 36px;
      color: #ffffff;
    }

    .box1_bot_pro {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
    }

    img {
      width: 44px;
      height: 44px;
      margin-left: 19px;
    }
  }
}

.box2 {
  width: 100%;
  height: 1031px;
  background: linear-gradient(137deg, #fff6f7 0%, #fffbeb 100%);
  border-radius: 0px 0px 0px 0px;

  .detail_btn {
    width: 178px;
    height: 68px;
    background: #ec4853;
    border-radius: 15px 15px 15px 15px;
    text-align: center;
    line-height: 68px;
    margin: 67px auto 0;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
  }

  .box2_title {
    font-weight: 500;
    font-size: 48px;
    color: #e6e6e6;
    padding-top: 50px;
  }

  .box2_cont {
    font-weight: bold;
    font-size: 36px;
    color: #333333;
  }

  .box2_lists {
    // margin-left: 360px;
    margin-top: 80px;

    .box2_list {
      width: 300px;
      height: 140px;
      border-radius: 0px 0px 0px 0px;
    }

    img {
      width: 80px;
      height: 80px;
      display: block;
      margin: 0 auto;
    }

    .hover {
      border-bottom: 3px solid #ec4853;

      .title {
        color: #ec4853;
      }
    }

    .title {
      font-weight: 500;
      font-size: 20px;
      color: #333333;
      text-align: center;
    }
  }

  .box2_list_cont {
    margin-top: 86px;

    .num {
      font-weight: bold;
      font-size: 40px;
      color: #ec4853;
      margin-right: 12px;
    }

    .num_title {
      font-weight: bold;
      font-size: 36px;
      color: #333333;
    }

    .num_cont {
      font-weight: 500;
      font-size: 18px;
      color: #666666;
      margin-top: 30px;
      width: 573px;
    }

    .num_btn {
      width: 178px;
      height: 68px;
      background: #ec4853;
      border-radius: 15px 15px 15px 15px;
      text-align: center;
      line-height: 68px;
      font-weight: 500;
      font-size: 20px;
      color: #ffffff;
      margin-top: 165px;
    }

    .main_img {
      width: 783px;
      margin: 0;
      height: 419px;
    }

    .box2_img {
      width: 400px;
      height: 300px;
      margin: 0 30px 0 0;
    }
  }
}

// box3
.box3 {
  margin-top: 40px;

  .box3_title {
    font-weight: 500;
    font-size: 48px;
    color: #e6e6e6;
    padding-top: 50px;
  }

  .box3_cont {
    font-weight: bold;
    font-size: 36px;
    color: #333333;
    margin-bottom: 70px;
  }

  .box3_lists {
    width: 600px;
    height: 120px;
    margin: 70px auto 60px;

    .box3_list {
      border-bottom: 1px solid #f5f5f5;
      width: 300px;
      height: 120px;
      line-height: 120px;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      color: #333333;
    }

    .hover {
      border-bottom: 1px solid #ec4853;
      color: #ec4853;
    }
  }

  .imgList {
    width: 1220px;
    margin: 0 auto;

    .imgList1 {
      width: 726px;
      height: 276px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .imgList2 {
      width: 454px;
      height: 276px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}

// box4
.box4 {
  width: 100%;
  height: 746px;
  background: linear-gradient(137deg, #fff6f7 0%, #fffbeb 100%);
  border-radius: 0px 0px 0px 0px;
  margin-top: 40px;

  .box4_left {
    // margin-left: 360px;

    .box4_left_title {
      width: 150px;
      font-weight: 500;
      font-size: 48px;
      color: #e6e6e6;
    }

    .box4_left_cont {
      font-weight: bold;
      font-size: 36px;
      color: #333333;
    }

    .left_list {
      width: 80px;
      height: 29px;
      font-weight: 500;
      font-size: 20px;
      color: #333333;
    }

    .hover {
      border-bottom: 5px solid #ec4853;
      color: #ec4853;
    }
  }

  .box4_right {
    margin-left: 126px;

    img {
      width: 297px;
      margin-right: 20px;
      height: 456px;
    }
  }
}

// box5
.box5 {
  margin-top: 40px;

  .box5_title {
    font-weight: 500;
    font-size: 48px;
    color: #e6e6e6;
    padding-top: 50px;
  }

  .box5_cont {
    font-weight: bold;
    font-size: 36px;
    color: #333333;
  }

  .box5_btn {
    width: 178px;
    height: 68px;
    border-radius: 47px 47px 47px 47px;
    border: 1px solid #cccccc;
    text-align: center;
    line-height: 68px;
    font-weight: 500;
    font-size: 20px;
    color: #999999;
    margin-right: 68px;
  }

  .hover {
    border: none;
    background-color: #ec4853;
    color: #fff;
  }

  .box5_left {
    box-shadow: 0px 4px 50px -11px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 20px 20px;
    width: 590px;
    height: 640px;

    .box5_img {
      width: 590px;
      height: 486px;
      border-radius: 20px 20px 0px 0px;
    }

    .b5_title {
      font-weight: 500;
      font-size: 20px;
      color: #333333;
      padding: 20px 25px 0 20px;
      width: 540px;
    }

    .b5_cont {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      width: 540px;
      padding: 0px 25px 0 20px;
    }
  }

  .box5_right {
    margin-left: 20px;

    .b5r_list {
      margin-bottom: 20px;
      width: 540px;
      height: 160px;
      background: #ffffff;
      box-shadow: 0px 4px 50px -11px rgba(0, 0, 0, 0.1);
      border-radius: 20px 20px 20px 20px;
      // border: 2px solid #EC4853;
      padding: 20px 25px;
    }

    .b5r_title {
      font-weight: 500;
      font-size: 20px;
      color: #333333;
    }

    .b5r_cont {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
      height: 66px;
    }

    .b5r_time {
      font-weight: 400;
      font-size: 18px;
      color: #999999;
    }

    .b5r_more_img {
      width: 19px;
      height: 19px;
      margin-left: 5px;
    }
  }
}
.see_more {
  width: 178px;
  height: 68px;
  background: #ec4853;
  border-radius: 15px 15px 15px 15px;
  text-align: center;
  line-height: 68px;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin: 80px auto 0;
}
</style>