<template>
  <div class="container">
    <img
      src="../assets/img/chanpinhefuwu.png"
      mode=""
      class="main_img"
    >
    <div
      class="flex space-between flex-center"
      style="padding-left: 80px"
    >
      <div class="left" style="padding-top: 26px">
        <div
          class="left_list"
          v-for="(item, index) in navList"
          :key="index"
          :class="navHover == item.value ? 'hover' : ''"
          @click="navClick(item, index)"
        >
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="right" v-if="navHover == 1">
        <div class="flex items-center  " >
          <div class="" style="margin-top: 123px;margin-left: 113px;">
            <div class="flex">
              <div class="right_title">01</div>
              <div class="right_cont">百霸智学是什么？</div>
            </div>
            <div class="right_pro">
              是一整套帮助学生高效学习和提分、帮助学校和机构赋能的具有确定性结果交付能力的教育和学习解决方案。
            </div>
            <div class="right_pro">
              主要包括四大部分组成：目标方案规划+引导学习+过程陪伴+AI导学及互动平台
            </div>
          </div>
          <img
            src="../assets/index/Element.png"
            mode=""
           
            class="nb_img"
          >
        </div>
        <div class="">
          <div class="" style="margin-top: 123px;margin-left: 113px;">
            <div class="flex">
              <div class="right_title">02</div>
              <div class="right_cont">百霸智学的优势</div>
            </div>
            <div class="flex " style="margin-top: 80px ;">
              <div class="main_cont">
                颠覆性的导学陪学模式，以学生为中心的“目标方案规划+引导学习+过程陪伴+AI系统互动”的创新模式，激发学生的自我学习动力，紧密贴合高考目标，以终为始，将 170 多种独创“教、学技术与方法”贯穿项目全过程，打通教和学的“最后一公里”，即掌握知识、锻炼思维、考出分数，升到理想学校。
              </div>
              <!-- <div class="right_list">
                <img
                  src="../assets/img/Frame.png"
                  mode=""
                  class="rl_img"
                >
                <div class="rl_title">颠覆传统模式</div>
                <div class="rl_cont">
                  基于“整体性学习”概念及“先见森林，再见树木”的教育哲学，颠覆“老师讲+学生练”的传统模式
                </div>
              </div>
              <div class="right_list">
                <img
                  src="../assets/img/Frame(1).png"
                  mode=""
                  class="rl_img"
                >
                <div class="rl_title">紧密贴合高考目标</div>
                <div class="rl_cont">
                  采用以学生为中心的“目标方案规划+引导学习+过程陪伴+AI系统互动”创新模式，紧密贴合高考目标
                </div>
              </div>
              <div class="right_list">
                <img
                  src="../assets/img/Frame(2).png"
                  mode=""
                  class="rl_img"
                >
                <div class="rl_title">独创170多种技法</div>
                <div class="rl_cont">
                  以终为始，将 170
                  多种独创“教、学技术与方法”贯穿项目全过程，确保学习结果呈现并达成目标
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="">
          <div class="" style="margin-left: 113px; margin-top: 123px">
            <div class="flex">
              <div class="right_title">03</div>
              <div class="right_cont">百霸智学的特色</div>
            </div>
            <div class="flex items-center flex-wrap" style="margin-top: 80px;width: 1400px;">
              <div class="right_list2 flex items-center">
                <div class="">
                  <div class="rl2_title">数据驱动的</div>
                  <div class="rl2_title">真正个性化学习方案</div>
                  <div class="rl2_cont">
                    基于学生现状，精准定位和分析学生学习情况、性格特点、目标院校等，制定深度个性化学习方案，真正的做到量身定制、因材施教。过程中不断收集和分析学生学习大数据，进行方案和计划的优化调整，确保学生学习始终保持在最优路径上。
                  </div>
                </div>
                <img
                  src="../assets/img/being-creative.png"
                  mode=""
                  class="rl2_img"
                >
              </div>
              <div class="right_list2 flex items-center">
                <div class="">
                  <div class="rl2_title">确保结果</div>
                  <div class="rl2_title">目标的达成</div>
                  <div class="rl2_cont">
                    教育本身具有的复杂性源于影响教育的诸多因素的不确定性，包括学校、家庭、学生、社会等，百霸智学通过科学的过程管控和创新的教学技术，最大限度降低不利因素影响，通过过程管控的确定性、计划达成的确定性，确保了学习结果的确定性。
                  </div>
                </div>
                <img
                  src="../assets/img/order-completed.png"
                  mode=""
                  class="rl2_img"
                >
              </div>
              <div class="right_list2 flex items-center">
                <div class="">
                  <div class="rl2_title">全分数段</div>
                  <div class="rl2_title">全科高效提升</div>
                  <div class="rl2_cont">
                    基于百霸智学理念、模式和方法的先进性，对高、中、低分数段学生全科学习都具有高效的提分效果，帮助高分段学生拔尖上名校，中分数段学生提优上好学校，低分数段学生补差有学校上。
                  </div>
                </div>
                <img
                  src="../assets/img/smart-people.png"
                  mode=""
                  class="rl2_img"
                >
              </div>
              <div class="right_list2 flex items-center">
                <div class="">
                  <div class="rl2_title">全程陪伴</div>
                  <div class="rl2_title">帮助心理调适</div>
                  <div class="rl2_cont">
                    通过陪学团队“线上+线下、大数据+AI
                    互动”的先进模式，不断帮助学生进行心理调适，营造最优学习环境氛围，降低和消除家长及学生的焦虑情绪，让学生始终在放松的状态下学习，不断提升学习自信，确保提分效果。
                  </div>
                </div>
                <img
                  src="../assets/img/conversation-businessman-customer.png"
                  mode=""
                  class="rl2_img"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right" v-if="navHover == 2">
        <div class="" style="margin-left: 113px; margin-top: 123px">
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">01</div>
              <div class="right_cont">百霸智学系统界面及功能展示</div>
            </div>
            <div class="" style="margin-top: 53px">
              <img
                src="../assets/img/img_v3_02ii_6a8016a8-07da-4064-a7a2-6028a4058ceg.png"
                mode=""
                class="right_img1"
              >
            </div>
          </div>
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">02</div>
              <div class="right_cont">百霸智育系统界面及功能展示</div>
            </div>
            <div class="" style="margin-top: 53px">
              <!-- <div class="main_cont">
                颠覆性的导学陪学模式，以学生为中心的“目标方案规划+引导学习+过程陪伴+AI系统互动”的创新模式，激发学生的自我学习动力，紧密贴合高考目标，以终为始，将 170 多种独创“教、学技术与方法”贯穿项目全过程，打通教和学的“最后一公里”，即掌握知识、锻炼思维、考出分数，升到理想学校。
              </div> -->
              <div class="flex items-center">
                <img
                  src="../assets/zxcp/Frame 4.png"
                  mode=""
                  class="right_img2"
                >
                <img
                  src="../assets/zxcp/Frame 7.png"
                  mode=""
                  class="right_img2"
                >
              </div>
              <img
                src="../assets/zxcp/Frame 2.png"
                mode=""
                class="right_img3"
              >
            </div>
          </div>
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">03</div>
              <div class="right_cont">百霸育儿系统界面及功能展示</div>
            </div>
            <div class="" style="margin-top: 53px">
              <img
                src="../assets/zxcp/Frame 1.png"
                mode=""
                class="right_img3"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="right" v-if="navHover == 3">
        <div class="" style="margin-left: 113px; margin-top: 123px">
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">01</div>
              <div class="right_cont">南京中环智学中心校区及场景展示</div>
            </div>
            <div class="nj_img" style="margin-top: 53px">
              <img src="../assets/index/nj/nj1.jpg" mode="">
              <img src="../assets/index/nj/nj3.jpg" mode="">
              <img src="../assets/index/nj/nj2.jpg" mode="">
            </div>
          </div>
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">02</div>
              <div class="right_cont">郑州智学中心校区及场景展示</div>
            </div>
            <div class="nj_img" style="margin-top: 53px">
              <img src="../assets/index/zz/1.jpg" mode="">
              <img src="../assets/index/zz/2.jpg" mode="">
              <img src="../assets/index/zz/3.jpg" mode="">
            </div>
          </div>
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">03</div>
              <div class="right_cont">宁波智学中心校区及场景展示</div>
            </div>
            <div class="flex flex-wrap nj_img" style="margin-top: 53px" >
              <img src="../assets/img/nj1.png" alt="" >
              <img src="../assets/img/nj2.png" alt="">
              <img src="../assets/img/nj3.png" alt="">
              <img src="../assets/img/nj4.png" alt="">
              <img src="../assets/img/nj5.png" alt="">
              <img src="../assets/img/nj6.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="right" v-if="navHover == 4">
        <div class="" style="margin-left: 113px; margin-top: 123px">
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">01</div>
              <div class="right_cont">校园高考实验班项目场景展示</div>
            </div>
            <div class="nj_img" style="margin-top: 53px">
              <img src="../assets/index/xy/xy1.jpg" mode="">
              <img src="../assets/index/xy/xy2.jpg" mode="">
              <img src="../assets/index/xy/xy3.jpg" mode="">
            </div>
          </div>
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">02</div>
              <div class="right_cont">假期集训营项目场景展示</div>
            </div>
            <div class="nj_img" style="margin-top: 53px">
              <img src="../assets/index/jq/1.jpg" mode="">
              <img src="../assets/index/jq/2.jpg" mode="">
              <img src="../assets/index/jq/3.jpg" mode="">
            </div>
          </div>
          <div class="" style="margin-top: 133px">
            <div class="flex">
              <div class="right_title">03</div>
              <div class="right_cont">机构合作项目场景展示</div>
            </div>
            <div class="nj_img" style="margin-top: 53px">
              <img src="../assets/index/jg/1.png" mode="">
              <img src="../assets/index/jg/2.png" mode="">
              <img src="../assets/index/jg/3.png" mode="">
              <img src="../assets/index/jg/4.png" mode="">
              <img src="../assets/index/jg/5.png" mode="">
              <img src="../assets/index/jg/6.png" mode="">
              <img src="../assets/index/jg/7.png" mode="">
              <img src="../assets/index/jg/8.png" mode="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "百霸智学及模式",
          value: 1,
        },
        {
          name: "百霸智学产品",
          value: 2,
        },
        {
          name: "百霸书房",
          value: 3,
        },
        {
          name: "百霸智学项目",
          value: 4,
        },
      ],
      navHover: 1,
    };
  },

  created() {
    this.navHover = this.$route.query.navHover+1||1;
    // this.getNews();
  },
  methods: {
    navClick(item) {
      this.navHover = item.value;
    },
  },
};
</script>

<style scoped lang="scss">
.main_img {
  width: 100%;
}

.left {
  .left_list {
    width: 126px;
    margin-bottom: 24px;

    .name {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
    }
  }

  .hover {
    .name {
      color: #ec4853;
      border-bottom: 1px solid #ec4853;
    }
  }
}

.right {
  width: 100%;
  border-radius: 0px 0px 0px 0px;
  border-left: 1px solid #d9d9d9;
  margin-left: 48px;

  .right_title {
    font-weight: bold;
    font-size: 40px;
    color: #ec4853;
  }

  .right_cont {
    font-weight: bold;
    font-size: 36px;
    color: #333333;
    margin-left: 12px;
  }

  .right_pro {
    // width: 573px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin-top: 71px;
  }

  .nb_img {
    width: 100%;
    height: auto;
    // margin-left: 157px;
    margin-top: 100px;
  }

  .right_list {
    width: 250px;
    height: 313px;
    background: #fff5f6;
    border-radius: 30px 30px 30px 30px;
    padding: 44px 33px 0 33px;
    margin-right: 90px;

    .rl_img {
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto 30px;
    }

    .rl_title {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .rl_cont {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
    }
  }

  .right_list2 {
    width: 569px;
    height: 272px;
    background: #fff5f6;
    border-radius: 30px 30px 30px 30px;
    padding: 40px 12px 0 45px;
    margin-right: 62px;
    margin-bottom: 50px;

    .rl2_img {
      width: 228px;
      height: 228px;
      margin-left: 14px;
    }

    .rl2_title {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
    }

    .rl2_cont {
      width: 322px;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
    }
  }

  .right_img1 {
    width: 100%;
    // height: 1263px;
  }

  .right_img2 {
    width: 580px;
    height: 435px;
    margin-right: 40px;
    margin-bottom: 40px;
  }

  .right_img3 {
    width: 100%;
    // height: 900px;
  }

  .nj_img {
    img {
      // width: 419px;
      height: 294px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
.main_cont{
  width: 997px;
height: 108px;
font-family: Source Han Sans SC, Source Han Sans SC;
font-weight: 500;
font-size: 18px;
color: #333333;
line-height: 36px;
text-align: left;
font-style: normal;
text-transform: none;
}
</style>