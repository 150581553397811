<template>
  <div class="container">
    <img src="../assets/img/zixun.png" mode="" class="main_img" />
    <div
      class="flex space-between"
      style="padding-left: 200px; padding-right: 128px"
    >
      <div class="left" style="padding-top: 26px">
        <div
          class="left_list"
          v-for="(item, index) in navList"
          :key="index"
          :class="navHover == item.value ? 'hover' : ''"
          @click="navClick(item, index)"
        >
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="right flex">
        <div class="" style="margin-left: 86px; margin-top: 100px">
          <div class="right_list">
            <div class="rl_title">
              {{ item.title }}
            </div>
            <div class="rl_cont">
              <div v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "公司新闻",
          value: 15,
        },
        {
          name: "行业资讯",
          value: 16,
        },
      ],
      navHover: 15,
      newList: [],
      item: {},
      id: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getNews();
  },

  methods: {
    navClick(item) {
      this.$router.push({ name: "newsHtml", query: { id: item.value } });
      // this.navHover = item.value
      // this.getNews()
      //   uni.navigateTo({
      //     url: "/pages/index/news?navHover=" + item.value,
      //   });
    },
    getNews() {
      this.$http({
        method: "post",
        url: "/Web.News/detail",
        data: {
          id: this.id,
        },
      })
        .then((data) => {
          this.item = data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.main_img {
  width: 100%;
  //   height: 750px;
}

.left {
  .left_list {
    width: 80px;
    margin-bottom: 24px;

    .name {
      font-weight: 400;
      font-size: 18px;
      color: #666666;
    }
  }

  .hover {
    .name {
      color: #ec4853;
      border-bottom: 1px solid #ec4853;
    }
  }
}

.right {
  width: 1553px;
  border-radius: 0px 0px 0px 0px;
  border-left: 1px solid #d9d9d9;
  margin-left: 48px;

  .right_title {
    font-weight: bold;
    font-size: 40px;
    color: #ec4853;
  }

  .right_cont {
    font-weight: bold;
    font-size: 36px;
    color: #333333;
    margin-left: 12px;
  }

  .right_pro {
    width: 573px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    margin-top: 71px;
  }

  .nb_img {
    width: 783px;
    height: 419px;
    margin-left: 157px;
    margin-top: 100px;
  }

  .right_img1 {
    width: 1200px;
    height: 1263px;
  }

  .right_img2 {
    width: 580px;
    height: 435px;
    margin-right: 40px;
    margin-bottom: 40px;
  }

  .right_img3 {
    width: 1200px;
    height: 900px;
  }

  .nj_img {
    img {
      width: 419px;
      height: 294px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
  .right_list {
    margin-bottom: 64px;
    .rl_img {
      width: 373px;
      height: 236px;
      border-radius: 0px 0px 0px 0px;
      margin-right: 27px;
    }
    .rl_title {
      font-weight: 500;
      font-size: 26px;
      color: #333333;
    }
    .rl_cont {
      width: 760px;
      font-family: Source Han Sans SC, Source Han Sans SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      margin: 14px 0;
      // overflow: hidden;
    }
    .rl_btn {
      width: 180px;
      height: 48px;
      background: #ec4853;
      border-radius: 0px 0px 0px 0px;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      .jiantou {
        width: 16px;
        height: 16px;
        margin-left: 40px;
      }
    }
  }
}
</style>