<template>
  <div class="header flex items-center space-between">
    <img
      src="../assets/img/logo@2x.png"
      alt=""
      class="logo_img"
      @click="index()"
    />
    <div class="navLists flex items-center">
      <div
        class="nav"
        v-for="(item, index) in navList"
        :key="index"
        :class="$store.state.navValue  == item.value ? 'hover' : ''"
        @click="navClick(item, index)"
      >
        <div class="nav_title">
          {{ item.name }}
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  // props: {
  // 	current: Number
  // },
  computed: {
    ...mapState(['navValue']),
  },
  data() {
    return {
      navList: [
        {
          name: "首页",
          value: 1,
        },
        {
          name: "公司介绍",
          value: 2,
        },
        {
          name: "产品和服务",
          value: 3,
        },
        {
          name: "学法课堂",
          value: 4,
        },
        // {
        // 	name: '百霸团队',
        // 	value: 5,
        // },
        {
          name: "最新资讯",
          value: 6,
        },
        {
          name: "联系合作",
          value: 7,
        },
      ],
      current: localStorage.getItem("navValue") || 1,
    };
  },

  methods: {
    index() {
      this.$router.push({ name: "indexHome" });
    },
    navClick(item) {
        // this.current = item.value;
		this.$store.dispatch('updatenavValue',item.value);
      localStorage.setItem("navValue", item.value);
      if (item.value == 1) {
        this.$router.push({ name: "indexHome" });
      } else if (item.value == 2) {
        this.$router.push({ name: "companyProfile" });
      } else if (item.value == 3) {
        this.$router.push({ name: "servicesHtml" });
      } else if (item.value == 4) {
        this.$router.push({ name: "classRoom" });
        // uni.navigateTo({
        // 	url: '/pages/index/classroom'
        // })
      } else if (item.value == 5) {
        // uni.navigateTo({
        // 	url: '/pages/index/about'
        // })
      } else if (item.value == 6) {
        this.$router.push({ name: "newsHtml" });
        // uni.navigateTo({
        // 	url: '/pages/index/news'
        // })
      } else if (item.value == 7) {
        this.$router.push({ name: "aboutUs" });
        // uni.navigateTo({
        // 	url: '/pages/index/news'
        // })
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 110px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0px 0px 0px 0px;

  .logo_img {
    width: 155px;
    height: 52px;
    margin-left: 240px;
    cursor: pointer;
  }

  .navLists {
    // margin-left: 500px;
    padding-right: 240px;
    cursor: pointer;
    .nav {
      margin-right: 48px;

      .nav_title {
        font-family: MyFont3;
        font-weight: 500;
        font-size: 15px;
        height: 40px;
        color: #333333;
      }
    }

    .hover {
      color: #ec4853;
      .nav_title {
        font-weight: bold;
        color: #ec4853;
      }
      .line {
        height: 3px;
        background: #ec4853;
        border-radius: 0px 0px 0px 0px;
      }
    }
  }
}
</style>