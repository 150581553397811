<template>
<div id="app">
  <Header />
    <router-view/>
    <Footer />
  </div>
 
</template>

<script>
import Header from './components/topNav.vue'
import Footer from './components/botNav.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style lang="scss">
@import '@/assets/css/public.scss';
.u-line-2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}
.u-line-4 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}
.u-line-1 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
