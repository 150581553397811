import Vue from 'vue'
import VueRouter from 'vue-router'
import indexHome from '@/view/indexHome.vue'
import companyProfile from '@/view/companyProfile.vue'
import servicesHtml from '@/view/servicesHtml.vue'
import classRoom from '@/view/classRoom.vue'
import newsHtml from '@/view/newsHtml.vue'
import newsDetail from '@/view/newsDetail.vue'
import aboutUs from '@/view/aboutUs.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'indexHome',
    component: indexHome,
  },
  {
    path: '/companyProfile',
    name: 'companyProfile',
    component: companyProfile,
  },
  {
    path: '/servicesHtml',
    name: 'servicesHtml',
    component: servicesHtml
  },
  {
    path: '/classRoom',
    name: 'classRoom',
    component: classRoom
  },
  {
    path: '/newsHtml',
    name: 'newsHtml',
    component: newsHtml
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  
  // 其他路由配置...
]

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({
      y: 0
  }),
  routes: routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = '百霸智学';
  next();
});
export default router